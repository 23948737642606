<template>
  <fragment v-if="!isLoading">
    <v-card-text class="d-flex">
      <v-avatar
        v-if="avatar === null"
        rounded
        size="120"
        class="me-6"
      >
        <v-img src="@/assets/images/avatars/1.png"></v-img>
      </v-avatar>
      <fragment v-else>
        <v-avatar
          v-if="changeAvatar"
          rounded
          size="120"
          class="me-6"
        >
          <img
            :src="previewImage === null ? rutaPublic + avatar : previewImage"
            class="rounded"
            alt="avatar"
          />
        </v-avatar>
        <v-avatar
          v-else
          rounded
          size="120"
          class="me-6"
        >
          <img
            :src="rutaPublic + avatar"
            class="rounded"
            alt="avatar"
          />
        </v-avatar>
      </fragment>

      <!-- upload photo -->
      <div>
        <v-row
          v-if="!onlyShow"
          no-gutters
        >
          <v-col
            cols="12"
            md="12"
          >
            <v-btn
              color="primary"
              class="me-3 mt-5"
              :disabled="onlyShow"
              @click="activeFileUpload"
            >
              <v-icon class="d-sm-none">
                {{ icons.mdiCloudUploadOutline }}
              </v-icon>
              <span class="d-none d-sm-block">{{ $t('lbl.changeFoto') }}</span>
            </v-btn>

            <v-file-input
              id="refInputEl"
              ref="refInputEl"
              v-model="avatar"
              accept="image/*"
              outlined
              dense
              label="Avatar"
              hide-details
              :hide-spin-buttons="true"
              :hide-input="true"
              prepend-icon=""
              @change="setAvatar"
              @click:clear="clearAvatar"
            ></v-file-input>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <p class="text-sm mt-5">
              {{ $t('lbl.infoFoto') }}
            </p>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row v-if="model.creado">
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              :disabled="onlyShow"
              color="primary"
              class="mb-4 me-3"
              @click="sendActivation()"
            >
              <span>{{ $t('btn.activar') }}</span>
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="model.name"
              :disabled="onlyShow"
              :label="$t('lbl.name')"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="model.apellidos"
              :disabled="onlyShow"
              :label="$t('lbl.apellidos')"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="model.email"
              :disabled="onlyShow"
              :label="$t('lbl.email')"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="model.role_id"
              dense
              outlined
              :label="$t('lbl.rol')"
              :items="roles"
              item-text="name"
              item-value="id"
              :disabled="onlyShow"
              @change="changeRol"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="model.active"
              dense
              outlined
              :label="$t('lbl.state')"
              :items="status"
              item-text="name"
              item-value="code"
              :disabled="onlyShow"
            ></v-select>
          </v-col>

          <v-col
            v-if="model.company_id"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="model.company.name"
              dense
              outlined
              disabled
              :label="$t('lbl.company')"
            ></v-text-field>
          </v-col>

          <!-- alert -->
          <v-col
            v-if="changeEmail"
            cols="12"
          >
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ $t('msg.profileChangeEmailTitle') }}
                  </p>
                  <!--<a
                    href="javascript:void(0)"
                    class="text-decoration-none warning--text"
                  >
                    <span class="text-sm">Resend Confirmation</span>
                  </a>-->
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-form>

      <!-- MOSTRAR DELETE -->
      <v-dialog
        v-model="isDialogActive"
        scrollable
        max-width="350px"
      >
        <v-card max-height="400px">
          <v-card-title>{{ `${$t('btn.activar')} ${model.name}` }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            {{ $t('msg.sendNotificationUser', { item: model.name }) }}
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              outlined
              @click="isDialogActive = !isDialogActive"
            >
              {{ $t('btn.close') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              :loading="loading"
              @click="confirmSendActivation()"
            >
              {{ $t('btn.send') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
// import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'

export default {
  // components: {
  //   AppCardCode,
  // },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      avatar: null,
      previewImage: null,
      changeAvatar: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      roles: [],
      model: {
        avatar: null,
        id: null,
        name: null,
        apellidos: null,
        email: null,
        role_id: null,
        active: true,
      },
      password: '',
      confirmation_password: '',
      permisos: [],
      permisosItem: [],
      loading: false,
      onlyShow: false,
      rules: {
        min: v => v.length >= 6 || this.$t('rules.min6'),
      },
      show: false,
      status: [],
      emailOrigin: null,
      isDialogActive: false,
    }
  },
  computed: {
    ...mapState({
      userUpdate: state => state.app.userUpdate,
      userUpdateAvatar: state => state.app.userUpdateAvatar,
    }),
    btnTitle() {
      return sessionStorage.getItem('user-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    title() {
      return sessionStorage.getItem('user-id') === null ? this.$t('btn.create') : this.$t('btn.edit')
    },
    changeEmail() {
      if (this.emailOrigin !== this.model.email) {
        return true
      }

      return false
    },
  },
  created() {
    this.status.push({ name: 'Activado', code: true })
    this.status.push({ name: 'Desactivado', code: false })
    this.getRoles()
    this.getItem()
    if (sessionStorage.getItem('onlyShow') !== null) {
      this.onlyShow = true
    }
  },
  methods: {
    ...mapMutations([
      'setUserUpdate',
      'setUserUpdateAvatar',
      'setSourcePermisos',
      'setPermisosTabLoading',
    ]),
    getItem() {
      this.setSourcePermisos([])
      this.setPermisosTabLoading(true)
      if (sessionStorage.getItem('user-id') !== null) {
        const id = sessionStorage.getItem('user-id')
        this.axios
          .get(`user/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.avatar = this.model.avatar
            this.changeAvatar = false
            this.emailOrigin = this.model.email
            this.setUserUpdate(this.model)

            this.getRolesPermisos()
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.changeAvatar = true
        this.setUserUpdate(this.model)
        this.isLoading = false
      }
    },

    activeFileUpload() {
      const refInputEl = document.getElementById('refInputEl')
      if (refInputEl != null) {
        refInputEl.click()
      }
    },
    getRoles() {
      this.axios
        .get(`role?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.roles = res.data.data
        })
    },
    getRolesPermisos() {
      this.axios
        .get('role/permisos', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            const result = res.data.data
            const rrr = result.filter(e => e.id === this.model.role_id)
            this.setSourcePermisos(rrr)
          }
        })
        .finally(() => this.setPermisosTabLoading(false))
    },
    changeRole() {
      this.setUserUpdate(this.model)
    },
    setAvatar() {
      this.previewImage = URL.createObjectURL(this.avatar)
      this.setUserUpdateAvatar(this.avatar)
      this.changeAvatar = true

      if (this.avatar && sessionStorage.getItem('user-id') !== null) {
        const formData = new FormData()
        if (this.avatar) {
          formData.append('avatar', this.avatar)
          this.axios
            .post(`user/change-avatar/${sessionStorage.getItem('user-id')}`, formData, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // eslint-disable-next-line no-empty
              if (response.data.success === false) {
              }
            })
            .catch(error => console.log(error))
        }
      }
    },
    clearAvatar() {
      this.previewImage = null
      this.changeAvatar = !this.changeAvatar
      if (sessionStorage.getItem('user-id') !== null) {
        const id = sessionStorage.getItem('user-id')
        this.axios
          .get(`user/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.avatar = res.data.data.data.avatar
          })
      }
    },
    changePassword() {
      if (this.userUpdate.email) {
        this.axios
          .post('forgot-password', { email: this.userUpdate.email })
          .then(response => {
            if (response.data.success === false) {
              if (response.data.data.status === 404) {
                this.$toast.error(this.$t('forgot.errorUserInex', { email: this.userUpdate.email }))
              } else if (response.data.data.status === 401) {
                this.$toast.error(this.$t('forgot.errorUserInac', { email: this.userUpdate.email }))
              }
            } else {
              this.$toast.info(this.$t('forgot.sendEmail', { email: this.userUpdate.email }))
            }
          })
          .catch(error => console.log(error))
      }
    },
    changeRol() {
      this.setSourcePermisos([])
      this.setPermisosTabLoading(true)
      if (this.model.role_id) {
        const role = this.roles.filter(e => e.id === this.model.role_id)

        if (role.length > 0) {
          sessionStorage.setItem('user-role-new-id', this.model.role_id)

          this.getRolesPermisos()
        }
      }
    },

    sendActivation() {
      this.isDialogActive = true
    },
    confirmSendActivation() {
      this.loading = true
      this.axios
        .post(
          'user/activation-again',
          { id: this.model.id },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else {
            this.$toast.info(this.$t('msg.sendEmail', { email: this.model.email }))
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.loading = false
          this.isDialogActive = false
        })
    },
  },
}
</script>
